import theme, {query} from '../../style/theme';
import styled from 'styled-components';

export const CenterSplash = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.constants.minGutter};
`;

interface propsSplashContent {
  centered?: boolean;
}
export const SplashContent = styled.div<propsSplashContent>`
  ${props => (props.centered ? {textAlign: 'center'} : {})}
  max-width: 720px;
  & > p,
  & > h2,
  input {
    margin: ${theme.typography.p1.lineHeight} 0;
    @media (${query.upto.phone}) {
      margin: ${theme.typography.mobile.p1.lineHeight} 0;
    }
    &:first-child {
      margin-top: 0;
    }
  }
`;

export const SubmitSplit = styled.div`
  display: block;
  text-align: center;
  button {
    margin: 0 0 30px 0;
  }
  p {
    margin-top: ${theme.typography.mobile.p1.lineHeight};
  }
  @media (${query.above.phone}) {
    display: flex;
    justify-items: stretch;
    align-items: center;
    flex-wrap: wrap;
    & > *:first-child {
      flex: 0;
    }
    & > *:last-child {
      margin-left: ${theme.typography.p1.fontSize};
      text-align: center;
      flex: 1;
    }
    p {
      margin: 0;
    }
  }
`;

interface propsMsg {
  show?: boolean;
}
export const Msg = styled.div<propsMsg>`
  ${props => (props.show ? {} : {display: 'none'})}
`;
