import React, {useState} from 'react';
import {H0, P1, P2} from '../../style/Typography';
import TextInput from '../../components/TextInput';
import {Button} from '../../style/Buttons';
import {PageProps} from '../../interfaces';
import Page from '../../components/Page';
import {CenterSplash, SplashContent, Msg, SubmitSplit} from './_styles';
import ThankYouSvg from './_ThankYouSvg';
import {submitDoNotSellRequest} from '../../utils/hubSpot';

interface propsRequestContent {
  onSubmit: React.FormEventHandler;
  busy?: boolean;
  errorMsg?: string;
}

const RequestContent = ({onSubmit, busy, errorMsg}: propsRequestContent) => (
  <SplashContent>
    <H0 responsive>Do Not Sell My Personal Information Request</H0>
    <P1 responsive>
      You have the right to tell us not to exchange any personal information
      you’ve shared with us for gain. The California Consumer Privacy Act (CCPA)
      refers to this as your right to say “do not sell” my personal information.
    </P1>
    <P1 responsive>
      On this page, you can opt out of the sale of your personal information
      associated with your email subscription with us. Please provide your email
      address in the form below in order for us to complete your request.
    </P1>
    <form onSubmit={onSubmit}>
      <TextInput
        responsive
        name="email"
        type="email"
        placeholder="Email address"
        color="charcoal"
        placeholderColor="gray"
        borderColor="gray"
        required
      />
      <SubmitSplit>
        <Button flex type="submit" color="yellow" textColor="charcoal">
          Submit
        </Button>
        <div>
          <Msg show={!!errorMsg}>
            <P2 color="red">{errorMsg}</P2>
          </Msg>
          <Msg show={!!busy}>
            <P2 color="gray">submitting...</P2>
          </Msg>
        </div>
      </SubmitSplit>
    </form>
  </SplashContent>
);

const SuccessContent = () => (
  <SplashContent centered>
    <ThankYouSvg />
    <H0 responsive>Thank you!</H0>
    <P1 responsive>
      Thank you for your submission.
      <br />
      You will receive an email shortly to confirm your request.
    </P1>
  </SplashContent>
);

export default (props: PageProps) => {
  const [submitted, setSubmitted] = useState(false);
  const [busy, setBusy] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setErrorMsg('');
    setBusy(true);

    const formData = new FormData(event.currentTarget as HTMLFormElement);

    submitDoNotSellRequest(formData)
      .then((resp) => {
        if (resp.status && resp.status === 'error') {
          console.info(resp);
          setErrorMsg(
            'Sorry, there was an error. Please check your information and try again.',
          );
        } else {
          setSubmitted(true);
        }
      })
      .catch((err) => {
        console.warn(err);
        setErrorMsg(
          'We encountered a server error. Please try again in a few minutes.',
        );
      })
      .finally(() => {
        setBusy(false);
      });
  };

  return (
    <Page
      layout="simple"
      {...props.pageContext}
      pagePath={props.location.pathname}
    >
      <CenterSplash>
        {submitted ? (
          <SuccessContent />
        ) : (
          <RequestContent {...{submitted, busy, errorMsg, onSubmit}} />
        )}
      </CenterSplash>
    </Page>
  );
};
